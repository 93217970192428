<template>
  <div class="round-rect step my-1 p-3">
    <b-field grouped class="pb-0 mb-0">
        <b-icon
            v-if="!isNewStep"
            class="drag-handle pt-5 pr-1"
            icon="drag"
            size="is-small">
        </b-icon>
        <b-icon
            v-if="isNewStep"
            class="pt-5 pr-1"
            icon="plus"
            size="is-small">
        </b-icon>
        <p class="title is-5 pt-3 pr-3 mb-0">{{ index }}</p>
        <editor
          class="is-flex-grow-1 mx-1"
          v-model.trim="step.sentence"
          :placeholder='"e.g. Heat the oven to 180ºC"'
          @input="updateStep"
          />
        <p class="control">
          <b-button v-if="isNewStep" type="is-primary" icon-right="plus" @click="addStep" />
          <b-button v-else icon-right="delete" @click="removeStep" />
        </p>
    </b-field>
    <div id="ingredients" v-if="!isNewStep" class="pt-0 mt-2 mx-6">
      <div class="columns is-vcentered is-1">
        <div class="column is-narrow">
          <p class="is-size-6">Ingredients</p>
        </div>
        <div class="column">
            <draggable
              v-model="step.ingredients"
              draggable=".ingredient-tag"
              @update="updateStep">
              <b-tag
                :key="item.ingredient.id" v-for="(item, index) in mappedIngredients"
                size="is-medium"
                class="ingredient-tag my-0"
                attached
                closable
                @close="removeIngredient(index)">
                <i>{{item.ingredient.optional ? "(Optional) ":""}}</i>{{ parseFloat((item.ingredient.amount * item.ratio).toFixed(2)) }} {{item.ingredient.unit}} <b>{{item.ingredient.name}}</b> ({{item.ingredient.prep}})
              </b-tag>
            </draggable>
            <b-button outlined type="is-primary" class="right" icon-left="plus" @click="openIngredientModal">Use ingredient</b-button>
            <AddStepIngredientModal v-if="false" /> <!-- Silence warning -->
        </div>
      </div>
    </div>
    <div id="tips" v-if="!isNewStep" class="pt-0 mt-0 mx-6">
      <div class="columns is-vcentered is-1">
        <div class="column is-narrow">
          <p class="is-size-6">Tips</p>
        </div>
        <div class="column">
          <draggable
            v-model="step.tips"
            draggable=".tip"
            handle=".drag-handle"
            ghostClass="drag-ghost"
            dragClass="drag-item"
            @update="updateStep">
            <EditTip
              v-for="(tip, index) in step.tips"
              :key="tip.id"
              :isSmall="true"
              :tipIn="tip"
              :isNewTip="false"
              @remove-tip="removeTip($event, index)"
              />
          </draggable>
          <EditTip
            :isNewTip="true"
            :isSmall="true"
            @add-tip="addTip($event)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EditTip from "@/components/EditTip"
import AddStepIngredientModal from "@/components/modals/AddStepIngredientModal"
import { v4 as uuidv4 } from 'uuid';
import Draggable from 'vuedraggable'
import Editor from "@/components/BaseEditor"
import debounce from "lodash/debounce"

export default {
  name: 'EditStep',
  components: {
    EditTip,
    AddStepIngredientModal,
    Draggable,
    Editor,
  },
  props: [
    "stepIn",
    "isNewStep",
    "index",
    "ingredients"
  ],
  data() {
    return {
      step: null,
    }
  },
  created() {
    this.step = this.stepIn ?? { id: null, sentence: null, tips: [], ingredients:[] }
  },
  computed: {
    mappedIngredients() {
      return this.step.ingredients.map(stepIngredient => {
        return { ingredient: this.ingredients.find(item => item.id === stepIngredient.id), ratio: stepIngredient.ratio }
      });
    }
  },
  methods: {
    addStep() {
      this.step.id = uuidv4();
      this.$emit('add-step', this.step);
      this.step = {id: null, sentence: null, tips:[], ingredients:[]};
    },
    removeStep() {
      this.$emit('remove-step', this.step);
    },
    updateStep: debounce(function () {
      this.$emit('update-step', this.step);
    }, 1000),

    // Ingredients
    openIngredientModal() {
      this.$buefy.modal.open({
          parent: this,
          component: AddStepIngredientModal,
          hasModalCard: true,
          trapFocus: true,
          props: {
            ingredients: this.ingredients
          },
          events: {
            'add-ingredient': value => {
              this.addIngredient(value.ingredient, value.ratio)
            }
          }
      })
    },
    addIngredient(ingredient, ratio) {
      // make sure the ingredient isn't already used on this step
      const alreadyUsed = this.step.ingredients.map((i) => { return i.id } ).indexOf(ingredient.id) >= 0;
      if (!alreadyUsed){
        this.step.ingredients.push({id: ingredient.id, ratio: ratio});
        this.$emit('update-step', this.step);
      }else {
        this.$buefy.toast.open({type: 'is-danger', message: `That ingredient is already used on this step!`});
      }
    },
    removeIngredient(index) {
      this.$delete(this.step.ingredients, index);
      this.$emit('update-step', this.step);
    },

    // Tips
    addTip(newTip) {
      newTip.id = uuidv4();
      this.step.tips.push(newTip);
      this.$emit('update-step', this.step);
    },
    removeTip(tip, index) {
      this.$buefy.dialog.confirm({
          title: 'Deleting Tip',
          message: `Are you sure you want to delete this tip?<br /><br /><strong>This action cannot be undone!</strong>`,
          confirmText: 'Delete Tip',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$delete(this.step.tips, index);
            this.$emit('update-step', this.step);
          }
      });
    },

  }
}
</script>

<style scoped>



</style>
