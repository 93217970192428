<template>
  <form action="">
      <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Add an ingredient</p>
              <button
                  type="button"
                  class="delete"
                  @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
              <b-field label="Ingredient">
                <b-select
                  placeholder="Select ingredient"
                  v-model="selected">
                    <option
                        v-for="option in ingredients"
                        :value="option"
                        :key="option.id">
                        {{option.amount}} {{option.unit}} {{option.name}} {{option.prep}}
                    </option>
                </b-select>
              </b-field>
              <b-field label="Ratio" message="How much do you want to use? e.g. 1 is all of it, 0.5 is half">
                  <b-numberinput
                      step="0.001"
                      min="0"
                      max="1"
                      v-model="ratio"
                      placeholder="How much to use?"
                      required >
                  </b-numberinput>
              </b-field>
              <p>Ouput: {{ parseFloat((selected.amount * ratio).toFixed(4)) }} {{ selected.unit }} <b>{{ selected.name }}</b> ({{ selected.prep }})</p>
          </section>
          <footer class="modal-card-foot">
              <b-button
                  label="Close"
                  @click="$emit('close')" />
              <b-button
                  label="Add"
                  :disabled="isAddDisabled"
                  type="is-primary"
                  @click="addIngredient"/>
          </footer>
      </div>
    </form>

</template>

<script>

export default {
  name: 'AddStepIngredientModal',
  props: {
    ingredients: Array
  },
  data() {
    return {
      selected: { id: null, name: "", amount: null, unit: null, prep: null, tips: []},
      ratio: 1.0
    }
  },
  computed: {
    isAddDisabled() {
      return this.selected.id == null || this.ratio == null
    }
  },
  methods: {
    addIngredient() {
      console.log(this.selected)
      console.log(this.ratio)
      let added = { ingredient: this.selected, ratio:  this.ratio}
      this.$emit("add-ingredient", added)
      this.$emit("close")
    }
  }
}
</script>

<style scoped>
</style>
