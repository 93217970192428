<template>
  <div id="steps">
      <h2>Method</h2>
      <ol>
        <li v-bind:key="step.id" v-for="step in steps">
          <StepItem :step="step" :ingredients="ingredients" />
        </li>
      </ol>
  </div>
</template>

<script>
import StepItem from './StepItem';

export default {
  name: 'StepList',
  components: {
    StepItem
  },
  props: [
    "steps",
    "ingredients"
  ]
}
</script>

<style scoped>

#steps {
  text-align: left;
}

</style>
