<template>
  <div>
    <h2 class="subtitle is-3">Ingredients</h2>
    <draggable
      v-model="ingredients"
      draggable=".ingredient"
      handle=".drag-handle"
      ghostClass="drag-ghost"
      dragClass="drag-item">
      <EditIngredient
        v-for="(ingredient, index) in ingredients"
        :key="ingredient.id"
        :ingredientIn="ingredient"
        :isNewIngredient="false"
        @remove-ingredient="remove(index)"
        @update-ingredient="update($event)" />
    </draggable>
    <EditIngredient
      :isNewIngredient="true"
      @add-ingredient="add($event)" />
  </div>
</template>

<script>
import EditIngredient from "@/components/EditIngredient"
import Draggable from 'vuedraggable'

export default {
  name: 'EditRecipeIngredients',
  components: {
    EditIngredient,
    Draggable
  },
  props: {
    value: Array,
  },
  computed: {
    ingredients: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    add(newIngredient) {
      this.ingredients.push(newIngredient);
      this.$emit('input', this.ingredients)
    },
    remove(index) {
      this.$buefy.dialog.confirm({
          title: 'Deleting Ingredient',
          message: `Are you sure you want to delete this ingredient? <br /></br /><strong>NOTE!</strong> If it is <strong>used in a step</strong>, it will be <strong>removed from there also</strong>. <br /><br /><strong>This action cannot be undone!</strong>`,
          confirmText: 'Delete Ingredient',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            const removedIngredient = this.ingredients[index];
            this.$delete(this.ingredients, index);
            this.$emit('ingredient-removed', removedIngredient);
            this.$emit('input', this.ingredients);
          }
      });
    },
    update() {
      this.$emit('input', this.ingredients)
    },
  }
}
</script>
