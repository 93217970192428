<template>
  <div>
      <ul>
        <li :key="ingredient.id" v-for="ingredient in ingredients">
          <IngredientItem :ingredient="ingredient.ingredient" :ratio="ingredient.ratio" :showTips="false" />
        </li>
      </ul>
  </div>
</template>

<script>
import IngredientItem from './IngredientItem';

export default {
  name: 'InlineIngredients',
  components: {
    IngredientItem
  },
  props: [
    "ingredients"
  ]
}
</script>

<style scoped>

</style>
