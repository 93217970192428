<template>
  <div class="round-rect ingredient my-2 p-3">
    <b-field grouped group-multiline class="pb-0 mb-0">
        <b-icon
            v-if="!isNewIngredient"
            class="drag-handle pt-5 pr-1"
            icon="drag"
            size="is-small">
        </b-icon>
        <b-icon
            v-if="isNewIngredient"
            class="pt-5 pr-1"
            icon=""
            size="is-small">
        </b-icon>
        <b-input
          class="is-flex-shrink-5 amount-input mr-0 ml-1"
          type="number"
          step="0.05"
          v-model.number="ingredient.amount"
          placeholder="e.g. 4">
        </b-input>
        <b-autocomplete
             class="is-flex-shrink-5 unit-input mr-0 ml-1"
              v-model.trim="ingredient.unit"
              ref="unitsAutocomplete"
              field="term"
              placeholder="e.g. tbsp"
              :data="filteredUnits"
              open-on-focus
              @select="option => selectedUnit(option)">
              <template #footer>
                   <a @click="openNewUnitModal(ingredient.unit)">
                       <span>+ Add new</span>
                   </a>
               </template>
               <template #empty>
                   <span><strong>{{ ingredient.unit }}</strong> not found!</span>
               </template>
           </b-autocomplete>
        <b-autocomplete
              class="is-flex-grow-1 mr-0 ml-1"
              custom-class="has-text-weight-bold"
              v-model.trim="ingredient.name"
              ref="nameAutocomplete"
              field="name"
              placeholder="e.g. onion"
              :data="filteredIngredients"
              open-on-focus
              @select="option => selectedIngredient(option)">
              <template #footer>
                   <a @click="openNewIngredientModal(ingredient.name)">
                       <span>+ Add new</span>
                   </a>
               </template>
               <template #empty>
                   <span><strong>{{ingredient.name}}</strong> not found!</span>
               </template>
           </b-autocomplete>
         <b-autocomplete
              class="is-flex-grow-5 ml-1 mr-2"
               v-model.trim="ingredient.prep"
               ref="prepAutocomplete"
               field="term"
               placeholder="e.g. chopped"
               :data="filteredPreps"
               open-on-focus
               @select="option => selectedPrep(option)">
               <template #footer>
                    <a @click="openNewPrepModal(ingredient.prep)">
                        <span>+ Add new</span>
                    </a>
                </template>
                <template #empty>
                    <span><strong>{{ingredient.prep}}</strong> not found!</span>
                </template>
            </b-autocomplete>
            <b-switch
              v-model="ingredient.optional"
              type="is-dark"
              passive-type="is-default"
              size="is-small"
              @input="update()">
              Opt.
            </b-switch>
            <p class="control">
              <b-button v-if="isNewIngredient" type="is-primary" icon-right="plus" @click="add" />
              <b-button v-else type="is-black" icon-right="delete" inverted outline size="is-small" @click="remove" />
            </p>
    </b-field>

    <div id="tips" v-if="!isNewIngredient" class="pt-1 mt-0 mx-6">
      <div class="columns is-vcentered is-1">
        <div class="column is-narrow">
          <p class="is-size-6">Tips</p>
        </div>
        <div class="column">
          <draggable
            v-model="ingredient.tips"
            draggable=".tip"
            handle=".drag-handle"
            ghostClass="drag-ghost"
            dragClass="drag-item"
            @update="update">
            <EditTip
              v-for="(tip, index) in ingredient.tips"
              :key="tip.id"
              :isSmall="true"
              :tipIn="tip"
              :isNewTip="false"
              @update-tip="update($event)"
              @remove-tip="removeTip($event, index)"
              />
          </draggable>
          <EditTip
            :isSmall="true"
            :isNewTip="true"
            @add-tip="addTip($event)"
            />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import db from "@/firebase/db"
import EditTip from "@/components/EditTip"
import Draggable from "vuedraggable"
import AddNewPrepModal from "@/components/modals/AddNewPrepModal"
import AddNewUnitModal from "@/components/modals/AddNewUnitModal"
import AddNewIngredientModal from "@/components/modals/AddNewIngredientModal"

export default {
  name: 'EditIngredient',
  components: {
    EditTip,
    Draggable
  },
  props: [
    "ingredientIn",
    "isNewIngredient"
  ],
  data() {
    return {
      ingredient: null,
      ingredients: [],
      preps: [],
      units: []
    }
  },
  firestore: {
    ingredients: db.collection('ingredients').orderBy("name", "asc"),
    preps: db.collection('prep_terms').orderBy("term", "asc"),
    units: db.collection('ingredient_units').orderBy("term", "asc"),
  },
  created() {
    this.ingredient = this.ingredientIn ?? {id: null, name: "", amount: null, unit: "", prep: "", tips:[], optional: false};
  },
  computed: {
    filteredIngredients() {
      return this.ingredients.filter(option => {
        return option.name.toLowerCase().indexOf(this.ingredient.name.toLowerCase()) >= 0
      });
    },
    filteredPreps() {
      return this.preps.filter(option => {
        return option.term.toLowerCase().indexOf(this.ingredient.prep.toLowerCase()) >= 0
      });
    },
    filteredUnits() {
      return this.units.filter(option => {
        return option.term.toLowerCase().indexOf(this.ingredient.unit.toLowerCase()) >= 0
      });
    },
  },
  methods: {
    selectedIngredient(option) {
      if (option == null) {
        return;
      }
      this.ingredient.name = option.name;
      this.ingredient.aisle = option.aisle;
      this.ingredient.ingredient_id = option.id;
      this.$emit('update-ingredient', this.ingredient);
    },
    selectedPrep(option) {
      if (option == null) {
        return;
      }
      this.ingredient.prep = option.term;
      this.$emit('update-ingredient', this.ingredient);
    },
    selectedUnit(option) {
      if (option == null) {
        return;
      }
      this.ingredient.unit = option.term;
      this.$emit('update-ingredient', this.ingredient);
    },
    add() {
      this.ingredient.id = uuidv4();
      this.ingredient.unit = this.ingredient.unit ?? ""; // set blank default unit
      this.$emit('add-ingredient', this.ingredient);
      this.ingredient = {id: null, name: "", amount: null, unit: "", prep: "", tips: [], optional: false};
    },
    remove() {
      this.$emit('remove-ingredient', this.ingredient);
    },
    update() {
      this.$emit('update-ingredient', this.ingredient);
    },
    addTip(newTip) {
      newTip.id = uuidv4();
      this.ingredient.tips.push(newTip);
      this.$emit('update-ingredient', this.ingredient);
    },
    removeTip(tip, index) {
      this.$buefy.dialog.confirm({
          title: 'Deleting Tip',
          message: `Are you sure you want to delete this tip?<br /><br /><strong>This action cannot be undone!</strong>`,
          confirmText: 'Delete Tip',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$delete(this.ingredient.tips, index);
            this.$emit('update-ingredient', this.ingredient);
          }
      });
    },
    openNewIngredientModal(input) {
      let cleansedInput = input.toLowerCase().trim()
      this.$buefy.modal.open({
          parent: this,
          component: AddNewIngredientModal,
          hasModalCard: true,
          trapFocus: true,
          props: {
            input: cleansedInput
          },
          events: {
            'ingredient-added': value => {
              this.ingredient.name = value;
            }
          }
      })
    },
    openNewPrepModal(input) {
      let cleansedInput = input.toLowerCase().trim()
      this.$buefy.modal.open({
          parent: this,
          component: AddNewPrepModal,
          hasModalCard: true,
          trapFocus: true,
          props: {
            input: cleansedInput
          },
          events: {
            'prep-added': value => {
              this.ingredient.prep = value;
            }
          }
      })
    },
    openNewUnitModal(input) {
      let cleansedInput = input.toLowerCase().trim()
      cleansedInput;
      this.$buefy.modal.open({
          parent: this,
          component: AddNewUnitModal,
          hasModalCard: true,
          trapFocus: true,
          props: {
            input: cleansedInput
          },
          events: {
            'unit-added': value => {
              console.log("unit-added "+value.term)
              this.ingredient.unit = value.term;
            }
          }
      })
    },

  }
}
</script>

<style>

.amount-input {
  width: 65px;
}

/* remove arrows from number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
