<template>
  <form action="">
      <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title mr-5">Add new prep term</p>
              <button
                  type="button"
                  class="delete"
                  @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
              <b-field label="Prep term">
                <b-input
                  placeholder="e.g. chopped"
                  v-model="value">
                </b-input>
              </b-field>
          </section>
          <footer class="modal-card-foot">
              <b-button
                  label="Close"
                  @click="$emit('close')" />
              <b-button
                  label="Add"
                  :disabled="isAddDisabled"
                  type="is-primary"
                  @click="add"/>
          </footer>
      </div>
    </form>

</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'AddNewPrepModal',
  props: {
    input: String
  },
  data() {
    return {
      value: null
    }
  },
  created() {
    this.value = this.input ?? ""
  },
  computed: {
    isAddDisabled() {
      return this.value == null || this.value.trim() == ""
    }
  },
  methods: {
    add() {

      let newTerm = this.value.toLowerCase().trim()
      if (newTerm == "") {
        return;
      }
      db.collection("prep_terms").add({
          created_at: Date.now(),
          term: newTerm,
      })
      .then(() => {
        this.$buefy.toast.open({type: 'is-success', message: `Prep term successfully added!`})
        this.$emit("prep-added", newTerm)
        this.$emit("close")
      })
      .catch((error) => {
        this.$buefy.toast.open({type: 'is-danger', message: `Error adding prep term: ${error}`})
      });

    }
  }
}
</script>

<style scoped>
</style>
