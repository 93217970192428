<template>
  <form action="">
      <Keypress key-event="keyup" :key-code="13" :preventDefault="true" @success="add"  />
      <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title mr-5">Add new ingredient</p>
              <button
                  type="button"
                  class="delete"
                  @click="$emit('close')"/>
          </header>
          <section class="modal-card-body">
              <b-field label="Ingredient name">
                <b-input
                  placeholder="e.g. onion"
                  v-model="value">
                </b-input>
              </b-field>
              <b-field label="Ingredient aisle">
                <b-select v-model="aisle">
                  <option v-for="(aisle) in aisles" :value="aisle" :key="aisle.id">{{aisle.name}}</option>
                 </b-select>
              </b-field>
          </section>
          <footer class="modal-card-foot">
              <b-button
                  label="Close"
                  @click="$emit('close')" />
              <b-button
                  label="Add"
                  :disabled="isAddDisabled"
                  type="is-primary"
                  @click="add"/>
          </footer>
      </div>
    </form>

</template>

<script>
import db from "@/firebase/db"

export default {
  name: 'AddNewIngredientModal',
  components: {
    Keypress: () => import('vue-keypress')
  },
  props: {
    input: String
  },
  data() {
    return {
      value: null,
      aisle: null,
      aisles: []
    }
  },
  created() {
    this.value = this.input ?? ""
  },
  firestore: {
    aisles: db.collection('ingredient_aisles').orderBy("position", "asc"),
  },
  computed: {
    isAddDisabled() {
      return this.value == null || this.value.trim() == "" || this.aisle == null
    }
  },
  methods: {
    add() {

      let newName = this.value.toLowerCase().trim();
      if (newName == "") {
        return;
      }
      let aisle = this.aisle;
      if (aisle == "") {
        return;
      }

      db.collection("ingredients").add({
          created_at: Date.now(),
          name: newName,
          aisle: aisle,
          tips: []
      })
      .then(() => {
        this.$buefy.toast.open({type: 'is-success', message: `Ingredient successfully added!`})
        this.$emit("ingredient-added", newName)
        this.$emit("close")
      })
      .catch((error) => {
        this.$buefy.toast.open({type: 'is-danger', message: `Error adding ingredient: ${error}`})
      });

    }
  }
}
</script>

<style scoped>
</style>
