<template>
  <div>
    <b-loading is-full-page v-model="isLoading"></b-loading>
    <b-notification
        v-model="isError"
        type="is-danger"
        aria-close-label="Close notification"
        :closable="false">
        <b>Error fetching recipe!</b><br />Try refreshing the page. If the error persists check your network connection or let BM know.
    </b-notification>
    <Keypress key-event="keyup" :modifiers="['ctrlKey']" :key-code="83" :preventDefault="true" @success="save"  />
    <div v-if="!isLoading && !isError" class="content m-5">
        <div>
          <h2 class="title is-3">{{ title }}</h2>
        </div>
        <div class="is-flex is-justify-content-flex-end">
          
          <div class="mx-1">
            <b-field horizontal>
                <b-select v-model="recipe.status" placeholder="Status" @input="save">
                    <option>draft</option>
                    <option>test</option>
                    <option>fix</option>
                    <option>live</option>
                  </b-select>
             </b-field>
         </div>
         <b-tooltip
            :label="this.recipe.is_free ? 'Free to non-members' : 'Make this recipe free to non-members'"
            type="is-success"
            position="is-top"
            size="is-large">
              <b-button
                  class="mx-1"
                  :type="this.recipe.is_free ? 'is-success' : 'is-default'"
                  icon-right = "star"
                  @click="toggleFree()">
              </b-button> 
         </b-tooltip>
         <div class="mx-1">
           <b-button outlined type="" class="right" icon-left="calendar" @click="toggleMealplans">View Mealplans</b-button>
         </div>
          <div class="mx-1">
            <b-button outlined type="is-primary" class="right" icon-left="check" @click="saveAndClose">Save & Close</b-button>
          </div>
          <div class="mx-1">
            <b-button type="is-primary" class="right" icon-left="content-save" @click="save">Save</b-button>
          </div>
        </div>
        <div class="columns">
            <div class="column my-5">
              <section id="general" >
                <h2 class="subtitle is-3">General</h2>
                <b-field v-if="recipe.is_variant" horizontal label="Variant of">
                  <b-input expanded disabled :value="recipe.base_recipe.title" />
                  <b-button
                    expanded
                    type="is-warning"
                    icon-left = "swap-horizontal"
                    @click="convertToBaseRecipe()">
                    Convert to Base
                  </b-button>   
                  <b-button
                    expanded
                    icon-right = "arrow-right"
                    @click="viewBaseRecipe()">
                    View Recipe
                  </b-button> 
                 </b-field>
                <b-field v-if="recipe.is_variant" horizontal label="Internal Name" class="mb-2"
                  message="* This is an internal name and will not be seen by the end user">
                  <b-input
                    v-model="recipe.name"
                    @input="saveDebounced"
                  />
                 </b-field>
                 <br v-if="recipe.is_variant"/>
                 <b-field v-if="isViewMealplans && mealplans != null" horizontal label="Used in mealplans">
                   <b-table
                     :data="mealplans"
                     :striped="true">
                     <b-table-column field="title" v-slot="props">
                       <p class="has-text-weight-bold">
                         {{ props.row.title }}
                       </p>
                     </b-table-column>
                     <b-table-column field="status" width=10 centered v-slot="props">
                         <span
                           :class="[
                                   'tag',
                                   {'is-danger is-light': props.row.status == 'draft'},
                                   {'is-warning': props.row.status == 'test'},
                                   {'is-success': props.row.status == 'live'}
                                 ]">
                             <b>{{ props.row.status }}</b>
                         </span>
                     </b-table-column>
                     <b-table-column field="date" width=20 centered v-slot="props">
                         <span class="tag is-primary">
                             {{ formatDate(props.row.date) }}
                         </span>
                     </b-table-column>
                     <b-table-column field="-" width=60 centered v-slot="props">
                       <b-button size="is-small" outlined icon-right="chevron-right" @click="viewMealPlan(props.row)">View</b-button>
                     </b-table-column>
                   </b-table>
                 </b-field>
                <b-field horizontal label="Recipe Title">
                  <b-input
                    v-model="recipe.title"
                    @input="saveDebounced"
                  />
                 </b-field>
                <b-field horizontal label="Description">
                  <editor v-model="recipe.description" @input="saveDebounced"/>
                 </b-field>
                <b-field horizontal label="Meal">
                    <b-select v-model="recipe.meal" placeholder="Select a meal" @input="save">
                        <option>lunch</option>
                        <option>dinner</option>
                        <option>brunch</option>
                        <option>lunch or dinner</option>
                        <option>lunch, dinner or brunch</option>
                      </b-select>
                 </b-field>
                <b-field horizontal label="Prep Time">
                  <b-input v-model="recipe.prepTime" placeholder="e.g. 20 mins" @input="saveDebounced"/>
                  </b-field>
                <b-field horizontal label="Cook Time">
                  <b-input v-model="recipe.cookTime" placeholder="e.g. 50 mins" />
                 </b-field>
                <b-field horizontal label="Serves">
                  <b-numberinput v-model="recipe.servings" placeholder="e.g. 2" type="is-light" controls-position="compact" :min="2" :max="8"></b-numberinput>
                </b-field>
              </section>
            </div>
             <section id="images" class="column is-one-third my-5">
               <EditImages
                 v-model="recipe.images"
                 @input="save"
               />
             </section>
        </div>
        <section id="tips" class="my-5">
          <EditTips
            v-model="recipe.tips"
            title="Before you begin..."
            @input="save"
          />
        </section>
        <section id="ingredients" class="my-5">
          <EditRecipeIngredients
            v-model="recipe.ingredients"
            @ingredient-removed="ingredientRemoved($event)"
            @input="save" />
        </section>
        <section id="method" class="my-5">
          <EditRecipeSteps
            v-model="recipe.steps"
            :ingredients="recipe.ingredients"
            @input="save" />
        </section>
      <br />
      <section id="notes" class="my-5">
        <h2 class="subtitle is-3">Notes (internal only)</h2>
        <editor v-model="recipe.notes" @input="saveDebounced"/>
      </section>
      <br />
      <h1 class="title is-1">Preview: {{ recipe.title }}</h1>
      <b-carousel v-if="recipe.images.length > 0" :autoplay="true">
          <b-carousel-item v-for="(image, i) in recipe.images" :key="i">
              <b-image class="image" :src="image.src"></b-image>
          </b-carousel-item>
      </b-carousel>
      <br />
      <p v-html="recipe.description" />
      <br />
      <p><b>🔪 Prep Time:</b> {{ recipe.prepTime }}</p>
      <p><b>👩‍🍳 Cook Time:</b> {{ recipe.cookTime }}</p>
      <p><b>👥 Serves:</b> {{ recipe.servings }}</p>
      <br />
      <IngredientList :ingredients="recipe.ingredients" />
      <br />
      <StepList :steps="recipe.steps" :ingredients="recipe.ingredients" />
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import EditTips from "@/components/EditTips"
import EditImages from "@/components/EditImages"
import EditRecipeIngredients from "@/components/EditRecipeIngredients"
import EditRecipeSteps from "@/components/EditRecipeSteps"
import IngredientList from "@/components/IngredientList"
import StepList from "@/components/StepList"
import Editor from "@/components/BaseEditor"
import db from "@/firebase/db"
import DateFormatter from "@/formatters/DateFormatter"

export default {
  name: 'RecipeView',
  components: {
    EditTips,
    EditImages,
    EditRecipeIngredients,
    EditRecipeSteps,
    IngredientList,
    StepList,
    Editor,
    Keypress: () => import('vue-keypress')
  },
  props: ['id'],
  data() {
    return {
      recipe: {},
      mealplans: null,
      isViewMealplans: false,
      isLoading: true,
      isError: false
    };
  },
  computed: {
    title() {
      if (this.recipe.name != null && this.recipe.name != ""){
        return `${this.recipe.title} (${this.recipe.name})`
      }
      return this.recipe.title ?? "New Recipe"
    },
    newStepIndex() {
      return this.recipe.steps?.length + 1 ?? 1
    }
  },
  created: function () {

    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchRecipe()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )

  },
  methods: {
    fetchRecipe() {

      // fetch our recipe
      db.collection('recipes')
        .doc(this.id)
        .get()
        .then(snapshot => {
          if (snapshot.exists) {
            this.recipe = snapshot.data();
          }else {
            this.isError = true;
          }
          this.isLoading = false;

          let message = `You're editing a <b>${this.recipe.is_variant ? "Variant":"BASE"}</b> recipe. It's status is <b>${this.recipe.status.toUpperCase()}</b>!`
          
          this.$buefy.toast.open({
                      message: message,
                      type: this.recipe.status == "live" ? 'is-danger':'is-warning',
                      position: 'is-top-right',
                      actionText: 'OK',
                      duration: 3000,
                      indefinite: this.recipe.status == "live",
                  });
          
        })
        .catch((error) => {
          this.isError = true;
          this.$buefy.notification.open({type: 'is-danger', message: `Error fetching recipe: ${error}`})
        });
    },
    saveDebounced: debounce(function () {
        this.save();
      }, 3000),
    saveAndClose(){
      this.save()
        .then(() => {
          this.$router.go(-1);
        })
    },
    async save () {
      return db.collection('recipes')
        .doc(this.id)
        .set(this.recipe)
        .then(() => {
          this.$buefy.toast.open({type: 'is-success', message: `Recipe successfully updated!`});
        })
        .catch((error) => {
          this.$buefy.toast.open({type: 'is-danger', message: `Error saving recipe: ${error}`});
        });
    },
    convertToBaseRecipe() {
      this.$buefy.dialog.confirm({
          title: 'Converting to a base recipe',
          message: `Are you sure you want to convert <b><i>"${this.recipe.title} (${this.recipe.name})"</i></b> to be a base? <br /><br />This action cannot be undone and some data can be lost.`,
          confirmText: 'Convert to base recipe',
          type: 'is-warning',
          hasIcon: true,
          onConfirm: () => {
            this.recipe.is_variant = false;
            this.recipe.name = "";
            this.save();
          }
        })
    },
    viewBaseRecipe() {
      this.$router.push({ name: 'RecipeView', params: { id: this.recipe.base_recipe.id } });
    },
    
    async toggleMealplans () {
      this.isViewMealplans = !this.isViewMealplans;

      // we fetch our meal plans only once to save on reads
      if (this.mealplans != null){
        return;
      }
      // gather all the related recipe IDs
      let recipeIds = await this.getAllRelatedRecipeIds();
      this.setMealPlans(recipeIds);
      
    },
    async getAllRelatedRecipeIds(){
      return new Promise((resolve) => {
        let ids = [this.id];
        if (this.recipe.is_variant){
          if (this.recipe.base_recipe.id != null) { ids.push(this.recipe.base_recipe.id); }
          // todo fetch the base recipe's variants list. 
          db.collection('recipes')
            .doc(this.recipe.base_recipe.id)
            .get()
            .then(snapshot => {
              if (snapshot.exists) {
                let baseRecipe = snapshot.data();
                if (baseRecipe.recipe_variants != null) { ids = ids.concat(Object.keys(baseRecipe.recipe_variants)); }  
                ids = [...new Set(ids)];
                resolve(ids);
              }
            })
        }else{
          // we're a base recipe!
          if (this.recipe.recipe_variants != null) { ids = ids.concat(Object.keys(this.recipe.recipe_variants)); } 
          ids = [...new Set(ids)];
          resolve(ids);
        }
      })
      
    },
    setMealPlans(recipeIds) {
      if (this.mealplans == null){

        for (let i = 0; i < recipeIds.length; i++){
          let id = recipeIds[i];
          db.collection("recipe_mealplans").doc(id)
          .get()
          .then(snapshot => {
            if (snapshot.data() == null){
              this.$buefy.toast.open({type: 'is-warning', message: `Recipe is not used in any meal plans`});
              return;
            }

            let mealPlanKeys = Object.keys(snapshot.data());
            mealPlanKeys.forEach(id => {
              db.collection("meal_plans").doc(id)
                .get()
                .then(snapshot => {
                  if (this.mealplans == null) {
                    this.mealplans = [];
                  }
                  // keep our id 🙄
                  let mealplan = {
                    id: id,
                    ...snapshot.data()
                  };
                  this.mealplans.push(mealplan);
                  this.mealplans.sort((a, b) => b.date - a.date);
                })
            });
          })
        }
        
      }
    },
    formatDate(dateTime) {
      return DateFormatter.displayDate(dateTime);
    },
    viewMealPlan(plan) {
      this.$router.push({ name: 'MealPlanView', params: { id: plan.id } })
    },
    ingredientRemoved(ingredient) {
      // on steps with ingredients
      this.recipe.steps.filter(step => step.ingredients.length>0).forEach((step) => {
        // find index of the removed ingredient
        const index = step.ingredients.map((i) => { return i.id } ).indexOf(ingredient.id);
        // ... and remove it
        if (index >= 0) this.$delete(step.ingredients, index);
      });
    },
    toggleFree() {
      this.recipe.is_free = !this.recipe.is_free;
      this.saveDebounced();
    }
  }
}

</script>
