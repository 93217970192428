<template>
  <div>
      <p v-html="step.sentence"></p>
      <InlineIngredients :ingredients="mappedIngredients" />
      <InlineTips :tips="step.tips" />
  </div>
</template>

<script>
import InlineIngredients from './InlineIngredients';
import InlineTips from './InlineTips';

export default {
  name: 'StepItem',
  components: {
    InlineTips,
    InlineIngredients
  },
  props: [
    "step",
    "ingredients"
  ],
  computed: {
    mappedIngredients() {
      return this.step.ingredients.map(stepIngredient => {
        return { ingredient: this.ingredients.find(item => item.id === stepIngredient.id), ratio: stepIngredient.ratio }
      });
    }
  },
}
</script>
