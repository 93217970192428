<template>
  <div >
  <h2 class="subtitle is-3">Method</h2>
  <draggable
    v-model="steps"
    draggable=".step"
    handle=".drag-handle"
    ghostClass="drag-ghost"
    dragClass="drag-item" >
    <EditStep
      v-for="(step, stepIndex) in steps"
      :key="step.id"
      :stepIn="step"
      :isNewStep="false"
      :index="stepIndex + 1"
      :ingredients="ingredients"
      @remove-step="remove(stepIndex)"
      @update-step="update($event)" />
    </draggable>
    <EditStep
      :isNewStep="true"
      :index="newStepIndex"
      @add-step="add($event)" />
    </div>
</template>

<script>
import EditStep from "@/components/EditStep"
import Draggable from 'vuedraggable'

export default {
  name: 'EditRecipeSteps',
  components: {
    EditStep,
    Draggable
  },
  props: {
    value: Array,
    ingredients: Array
  },
  computed: {
    steps: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    newStepIndex() {
      return this.steps?.length + 1 ?? 0;
    }
  },
  methods: {
    add(newStep) {
      this.steps.push(newStep);
      this.$emit('input', this.steps)
    },
    remove(index) {
      this.$buefy.dialog.confirm({
          title: 'Deleting Step',
          message: `Are you sure you want to delete this step? <br /><br /><strong>This action cannot be undone.</strong>`,
          confirmText: 'Delete Step',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: () => {
            this.$delete(this.steps, index);
            this.$emit('input', this.steps)
          }
      });
    },
    update() {
      this.$emit('input', this.steps)
    },
  }
}
</script>
